var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"renew-polis panel is-primary"},[_c('h1',{staticClass:"title is-6 panel-heading mb-0"},[_vm._v("Продление полиса")]),_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Тип полиса","type":{
            'is-danger': _vm.$v.type.$error || 'type' in _vm.serverValid,
            'is-success': !_vm.$v.type.$invalid && _vm.$v.type.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.type.$error }}},[_c('b-select',{attrs:{"placeholder":"Выберите тип полиса","expanded":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Страхование ОСАГО")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Страхование КАСКО")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Страхование Имущества")]),_c('option',{attrs:{"value":"4"}},[_vm._v("Страхование Жизни")])])],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Серия полиса","type":{
            'is-danger': _vm.$v.series.$error || 'series' in _vm.serverValid,
            'is-success': !_vm.$v.series.$invalid && _vm.$v.series.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.series.$error }}},[_c('b-input',{attrs:{"placeholder":"Серия полиса"},on:{"blur":function($event){_vm.onTouchField('series');
              _vm.removeKeyFromServerValid('series');}},model:{value:(_vm.series),callback:function ($$v) {_vm.series=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"series"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Номер полиса","type":{
            'is-danger': _vm.$v.number.$error || 'number' in _vm.serverValid,
            'is-success': !_vm.$v.number.$invalid && _vm.$v.number.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.number.$error }}},[_c('b-input',{attrs:{"placeholder":"Номер полиса"},on:{"blur":function($event){_vm.onTouchField('number');
              _vm.removeKeyFromServerValid('number');}},model:{value:(_vm.number),callback:function ($$v) {_vm.number=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"number"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Дата выдачи","type":{
            'is-danger': _vm.$v.startDate.$error || 'startDate' in _vm.serverValid,
            'is-success': !_vm.$v.startDate.$invalid && _vm.$v.startDate.$dirty,
          },"message":{
            'Некорректно указана дата': _vm.$v.startDate.$error,
          }}},[_c('IMaskComponent',{staticClass:"input",class:{
              'is-danger': _vm.$v.startDate.$error || 'startDate' in _vm.serverValid,
              'is-success': !_vm.$v.startDate.$invalid && _vm.$v.startDate.$dirty,
            },attrs:{"placeholder":"00.00.0000 00:00","inputmode":"numeric","blocks":_vm.startDateMaskBlocks,"mask":'D{.}M{.}Y h{:}m'},on:{"blur":function($event){_vm.onTouchField('startDate');
              _vm.removeKeyFromServerValid('startDate');}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"startDate"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Дата окончания","type":{
            'is-danger': _vm.$v.endDate.$error || 'endDate' in _vm.serverValid,
            'is-success': !_vm.$v.endDate.$invalid && _vm.$v.endDate.$dirty,
          },"message":{
            'Некорректно указана дата': _vm.$v.endDate.$error,
          }}},[_c('IMaskComponent',{staticClass:"input",class:{
              'is-danger': _vm.$v.endDate.$error || 'endDate' in _vm.serverValid,
              'is-success': !_vm.$v.endDate.$invalid && _vm.$v.endDate.$dirty,
            },attrs:{"placeholder":"00.00.0000 00:00","inputmode":"numeric","blocks":_vm.endDateMaskBlocks,"mask":'D{.}M{.}Y h{:}m'},on:{"blur":function($event){_vm.onTouchField('endDate');
              _vm.removeKeyFromServerValid('endDate');}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"endDate"}})],1)],1),(_vm.showCarFields)?_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Марка авто","type":{
            'is-danger': _vm.$v.marka.$error || 'marka' in _vm.serverValid,
            'is-success': !_vm.$v.marka.$invalid && _vm.$v.marka.$dirty,
          },"message":{ 'Некорректно заполнено поле': _vm.$v.marka.$error }}},[_c('b-autocomplete',{attrs:{"placeholder":"Марка авто","data":_vm.markaData,"keep-first":false,"open-on-focus":false,"clearable":true,"loading":_vm.isFetching},on:{"typing":function($event){return _vm.getDadata($event, 'marka')},"blur":function($event){_vm.onTouchField('marka');
              _vm.removeKeyFromServerValid('marka');}},model:{value:(_vm.marka),callback:function ($$v) {_vm.marka=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"marka"}})],1)],1):_vm._e(),(_vm.showCarFields)?_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Модель авто","type":{
            'is-danger': _vm.$v.model.$error || 'model' in _vm.serverValid,
            'is-success': !_vm.$v.model.$invalid && _vm.$v.model.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.model.$error }}},[_c('b-input',{attrs:{"placeholder":"Модель авто"},on:{"blur":function($event){_vm.onTouchField('model');
              _vm.removeKeyFromServerValid('model');}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"model"}})],1)],1):_vm._e(),(_vm.showCarFields)?_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Гос номер авто","type":{
            'is-danger': _vm.$v.carNumber.$error || 'carNumber' in _vm.serverValid,
            'is-success': !_vm.$v.carNumber.$invalid && _vm.$v.carNumber.$dirty,
          },"message":{
            'Гос номер должен состоять из кириллицы': !_vm.$v.carNumber.alphaNum,
            'Гос номер авто 8-9 символов': !_vm.$v.carNumber.minLength,
          }}},[_c('b-input',{attrs:{"inputmode":"numeric","placeholder":"A000AA152"},on:{"blur":function($event){_vm.onTouchField('carNumber');
              _vm.removeKeyFromServerValid('carNumber');}},model:{value:(_vm.carNumber),callback:function ($$v) {_vm.carNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"carNumber"}})],1)],1):_vm._e(),(_vm.showCarFields)?_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop field-with-checkbox"},[_c('b-field',{attrs:{"label":"VIN код авто","type":{
            'is-danger': _vm.$v.vin.$error || 'vin' in _vm.serverValid,
            'is-success': !_vm.$v.vin.$invalid && _vm.$v.vin.$dirty,
          },"message":{ 'VIN код авто 17 символов': _vm.$v.vin.$error }}},[_c('IMaskComponent',{staticClass:"input",class:{
              'is-danger': _vm.$v.vin.$error || 'vin' in _vm.serverValid,
              'is-success': !_vm.$v.vin.$invalid && _vm.$v.vin.$dirty,
            },attrs:{"placeholder":"VIN","inputmode":"numeric","disabled":_vm.withoutVin,"mask":'*****************',"prepare":function (str) { return str.toUpperCase(); }},on:{"blur":function($event){return _vm.onTouchField('vin')}},model:{value:(_vm.vin),callback:function ($$v) {_vm.vin=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"vin"}})],1),_c('b-field',{staticClass:"checkbox-vin"},[_c('b-checkbox',{attrs:{"size":"is-small"},model:{value:(_vm.withoutVin),callback:function ($$v) {_vm.withoutVin=$$v},expression:"withoutVin"}},[_vm._v("VIN отсутствует")])],1)],1):_vm._e(),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop"},[_c('b-field',{attrs:{"label":"Стоимость полиса","type":{
            'is-danger': _vm.$v.amount.$error || 'amount' in _vm.serverValid,
            'is-success': !_vm.$v.amount.$invalid && _vm.$v.amount.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.amount.$error }}},[_c('b-input',{attrs:{"placeholder":"Стоимость полиса","icon-right":"ruble-sign"},on:{"blur":function($event){_vm.onTouchField('amount');
              _vm.removeKeyFromServerValid('amount');}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-full-tablet is-two-thirds-desktop"},[_c('b-field',{attrs:{"label":"Название страховой","type":{
            'is-danger':
              _vm.$v.insuranceName.$error || 'insuranceName' in _vm.serverValid,
            'is-success':
              !_vm.$v.insuranceName.$invalid && _vm.$v.insuranceName.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.insuranceName.$error }}},[_c('b-input',{attrs:{"placeholder":"Название страховой"},on:{"blur":function($event){_vm.onTouchField('insuranceName');
              _vm.removeKeyFromServerValid('insuranceName');}},model:{value:(_vm.insuranceName),callback:function ($$v) {_vm.insuranceName=$$v},expression:"insuranceName"}})],1)],1)]),_c('div',{staticClass:"button-wrapper"},[_c('b-button',{attrs:{"type":"is-success","native-type":"submit"}},[_vm._v("Создать")]),_c('b-button',{attrs:{"type":"is-info","native-type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.submit(true)}}},[_vm._v("Создать и выдать")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }