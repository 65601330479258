<template>
  <b-table
    :data="tableData"
    :bordered="false"
    :striped="true"
    :narrowed="false"
    :hoverable="true"
    :loading="false"
    :focusable="false"
    :mobile-cards="true"
  >
    <b-table-column field="lastName" label="Фамилия" v-slot="props">
      {{ props.row.lastName }}
    </b-table-column>

    <b-table-column field="firstName" label="Имя" v-slot="props">
      {{ props.row.firstName }}
    </b-table-column>

    <b-table-column field="birthday" label="Имя" v-slot="props">
      {{ props.row.birthday }}
    </b-table-column>

    <b-table-column field="phone" label="Телефон" v-slot="props">
      {{ props.row.phone }}
    </b-table-column>

    <b-table-column
      field="link"
      label="Перейти"
      width="170"
      centered
      v-slot="props"
    >
      <b-button
        size="is-small"
        type="is-link"
        @click.prevent="setCongratulation(props.row.id)"
        >Поздравить</b-button
      >
    </b-table-column>

    <template #empty>
      <div class="has-text-centered">
        <b-icon icon="ban" size="is-medium" type="is-danger"></b-icon>
        <b>Пусто</b>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'TableBirthday',
  props: ['dataBirthday'],
  data() {
    return {
      tableData: this.dataBirthday || [],
    };
  },
  watch: {
    dataBirthday(data) {
      this.tableData = data;
    },
  },
  methods: {
    async setCongratulation(id) {
      const success = await this.$store.dispatch(
        'client/setCongratulation',
        id
      );

      if (success) {
        this.tableData = this.tableData.filter((item) => item.id !== id);
      }
    },
  },
};
</script>

<style lang="scss"></style>
