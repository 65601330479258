<template>
  <div class="">
    <article
      class="client-details"
      v-if="Number(props.row.clientData.type) === 1"
    >
      <div class="info">
        <h6>ФИО:</h6>
        <p>
          {{
            props.row.clientData.lastName +
            ' ' +
            props.row.clientData.firstName +
            ' ' +
            props.row.clientData.patronymic
          }}
        </p>
      </div>
      <div class="info">
        <h6>Телефон:</h6>
        <p>
          {{
            props.row.clientData.phone !== null
              ? props.row.clientData.phone
              : 'Отсутствует'
          }}
        </p>
      </div>
      <div class="info">
        <h6>Email:</h6>
        <p>
          {{
            props.row.clientData.email !== null
              ? props.row.clientData.email
              : 'Отсутствует'
          }}
        </p>
      </div>
    </article>
    <article
      class="client-details"
      v-else-if="Number(props.row.clientData.type) === 2"
    >
      <div class="info">
        <h6>Наименование организации:</h6>
        <p>{{ props.row.clientData.orgName }}</p>
      </div>
      <div class="info">
        <h6>Телефон:</h6>
        <p>
          {{
            props.row.clientData.phone !== null
              ? props.row.clientData.phone
              : 'Отсутствует'
          }}
        </p>
      </div>
      <div class="info">
        <h6>Email:</h6>
        <p>
          {{
            props.row.clientData.email !== null
              ? props.row.clientData.email
              : 'Отсутствует'
          }}
        </p>
      </div>
    </article>
    <article class="client-details" v-else>
      <div class="info">
        <h6>ФИО:</h6>
        <p>
          {{
            props.row.clientData.lastName + ' ' + props.row.clientData.firstName
          }}
        </p>
      </div>
      <div class="info">
        <h6>Телефон:</h6>
        <p>
          {{
            props.row.clientData.phone !== null
              ? props.row.clientData.phone
              : 'Отсутствует'
          }}
        </p>
      </div>
      <div class="info">
        <h6>Email:</h6>
        <p>
          {{
            props.row.clientData.email !== null
              ? props.row.clientData.email
              : 'Отсутствует'
          }}
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'DetailTemplate',
  props: ['props'],
};
</script>

<style lang="scss"></style>
