<template>
  <div class="polises-utils panel is-primary">
    <h1 class="title is-6 panel-heading mb-0">Работа с полисами</h1>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Просроченные полисы">
        <TablePolisesExpired :dataExpired="dataExpired" />
      </b-tab-item>

      <b-tab-item label="Заканчивающиеся полисы">
        <TablePolisesExpires :dataExpires="dataExpires" />
      </b-tab-item>

      <b-tab-item label="Полисы к продлению">
        <TablePolisesToRenew :dataToRenew="dataToRenew" />
      </b-tab-item>

      <b-tab-item label="Дни рождения клиентов">
        <TableBirthday :dataBirthday="dataBirthday" />
      </b-tab-item>
    </b-tabs>

    <div class="button-wrapper">
      <b-button type="is-primary" outlined tag="router-link" to="/workspace"
        >Закрыть</b-button
      >
    </div>
  </div>
</template>

<script>
import TablePolisesExpired from '@/components/polisesUtils/TablePolisesExpired';
import TablePolisesExpires from '@/components/polisesUtils/TablePolisesExpires';
import TablePolisesToRenew from '@/components/polisesUtils/TablePolisesToRenew';
import TableBirthday from '@/components/polisesUtils/TableBirthday';

export default {
  name: 'PolisesUtils',
  data() {
    return {
      activeTab: null,
      dataExpired: [],
      dataExpires: [],
      dataToRenew: [],
      dataBirthday: [],
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    this.$store.commit('toggleLoader', false);

    this.openTab();
  },
  watch: {
    activeTab(tab) {
      switch (Number(tab)) {
        case 0:
          this.getExpiredPolises();
          break;

        case 1:
          this.getExpiresPolises();
          break;

        case 2:
          this.getRenewPolises();
          break;

        case 3:
          this.getBirthdays();
          break;

        default:
          this.getExpiredPolises();
          break;
      }
    },
  },
  methods: {
    openTab() {
      switch (this.$route.params.tab) {
        case 'expired':
          this.activeTab = 0;
          break;

        case 'expires':
          this.activeTab = 1;
          break;

        case 'to-renew':
          this.activeTab = 2;
          break;

        case 'birthday':
          this.activeTab = 3;
          break;

        default:
          this.activeTab = 0;
          break;
      }
    },
    async getExpiredPolises() {
      this.dataExpired = await this.$store.dispatch('polis/getExpiredPolises');
    },
    async getExpiresPolises() {
      this.dataExpires = await this.$store.dispatch('polis/getExpiresPolises');
    },
    async getRenewPolises() {
      this.dataToRenew = await this.$store.dispatch('polis/getRenewPolises');
    },
    async getBirthdays() {
      this.dataBirthday = await this.$store.dispatch('client/getBirthdays');
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        phone: this.phone,
        firstName: this.firstName,
        lastName: this.lastName,
        patronymic: this.patronymic,
        gender: this.gender,
        email: this.email,
        birthday: this.birthday,
        birthCity: this.birthCity,
        passportSeries: this.passportSeries,
        passportNumber: this.passportNumber,
        passportDate: this.passportDate,
        passportIssue: this.passportIssue,
        passportIssueCode: this.passportIssueCode,
        addrRegion: this.addrRegion,
        addrCity: this.addrCity,
        addrStreet: this.addrStreet,
        addrHouse: this.addrHouse,
        addrApartment: this.addrApartment,
      };

      this.$store.dispatch('client/newClient', clientData);
    },
  },
  components: {
    TablePolisesExpired,
    TablePolisesExpires,
    TablePolisesToRenew,
    TableBirthday,
  },
};
</script>

<style lang="scss">
.polises-utils {
  display: flex;
  flex-direction: column;
  height: 100%;

  .b-tabs {
    margin-top: 10px;
  }

  .table td,
  .table th {
    vertical-align: middle;

    @media screen and(max-width:1400px) {
      font-size: 14px;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    padding: 20px;
  }
}
</style>
