<template>
  <b-table
    :data="tableData"
    :bordered="false"
    :striped="true"
    :narrowed="false"
    :hoverable="true"
    :loading="false"
    :focusable="false"
    :mobile-cards="true"
    :opened-detailed="defaultOpenedDetails"
    detailed
    detail-key="id"
    :show-detail-icon="true"
    @details-open="clickRow"
  >
    <b-table-column field="type" label="Тип полиса" v-slot="props">
      {{ convertPolisType(props.row.type) }}
    </b-table-column>

    <b-table-column field="series" label="Серия полиса" v-slot="props">
      {{ props.row.series }}
    </b-table-column>

    <b-table-column field="number" label="Номер полиса" v-slot="props">
      {{ props.row.number }}
    </b-table-column>

    <b-table-column
      field="insuranceName"
      label="Название страховой"
      v-slot="props"
    >
      {{ props.row.insuranceName }}
    </b-table-column>

    <b-table-column field="startDate" label="Дата выдачи" v-slot="props">
      {{ formatDateTime(props.row.startDate) }}
    </b-table-column>

    <b-table-column field="endDate" label="Дата окончания" v-slot="props">
      {{ formatDateTime(props.row.endDate) }}
    </b-table-column>

    <b-table-column field="status" label="Статус" centered v-slot="props">
      <b-tag :type="tagClassStatus(props.row.status)">
        {{ convertPolisStatus(props.row.status) }}</b-tag
      >
    </b-table-column>

    <b-table-column
      field="action"
      label="Действие"
      width="150"
      centered
      v-slot="props"
    >
      <b-button
        class="btn-polis-renew"
        size="is-small"
        type="is-primary is-light"
        outlined
        @click.prevent="polisToRenew(props.row)"
        >Продлить полис</b-button
      >
      <b-button
        size="is-small"
        type="is-danger is-light"
        outlined
        v-if="Number(props.row.status)"
        @click.prevent="polisCancelled(props.row)"
        >Отменить полис</b-button
      >
    </b-table-column>

    <template #detail="props">
      <DetailTemplate :props="props" />
    </template>

    <template #empty>
      <div class="has-text-centered">
        <b-icon icon="ban" size="is-medium" type="is-danger"></b-icon>
        <b>Ничего не найдено</b>
      </div>
    </template>
  </b-table>
</template>

<script>
import dayjs from 'dayjs';
import DetailTemplate from './DetailTemplate';

export default {
  name: 'TablePolisesExpires',
  props: ['dataExpires'],
  data() {
    return {
      tableData: this.dataExpires || [],
      defaultOpenedDetails: [1],
    };
  },
  watch: {
    dataExpires(data) {
      data.forEach((row) => {
        row.clientData = {};
      });

      this.tableData = data;
    },
  },
  methods: {
    async clickRow(row) {
      const newTableData = [...this.tableData];
      const clientData = await this.$store.dispatch(
        'client/getClientContact',
        row.clientId
      );

      for (let index = 0; index < newTableData.length; index++) {
        const item = newTableData[index];

        if (item.clientId === row.clientId) {
          item.clientData = clientData;
          break;
        }
      }

      this.tableData = newTableData;
    },
    async polisCancelled(rowData) {
      const success = await this.$store.dispatch('polis/polisCancelled', {
        clientId: rowData.clientId,
        polisId: rowData.id,
      });

      if (success) {
        this.tableData = this.tableData.filter((item) => item.id != rowData.id);
      }
    },
    async polisToRenew(rowData) {
      const success = await this.$store.dispatch(
        'polis/polisToRenew',
        rowData.id
      );

      if (success) {
        this.tableData = this.tableData.filter((item) => item.id != rowData.id);
      }
    },
    convertPolisType(type) {
      let typeText = '';

      switch (Number(type)) {
        case 1:
          typeText = 'Страхование ОСАГО';
          break;

        case 2:
          typeText = 'Страхование КАСКО';
          break;

        case 3:
          typeText = 'Страхование Имущества';
          break;

        case 4:
          typeText = 'Страхование Жизни';
          break;

        default:
          break;
      }

      return typeText;
    },
    tagClassStatus(status) {
      let tagClass = '';

      switch (Number(status)) {
        case 0:
          tagClass = 'is-danger';
          break;

        case 1:
          tagClass = 'is-success';
          break;
        case 2:
          tagClass = 'is-info';
          break;

        default:
          break;
      }

      return tagClass;
    },
    convertPolisStatus(status) {
      let statusText = '';

      switch (Number(status)) {
        case 0:
          statusText = 'Отменен';
          break;

        case 1:
          statusText = 'Создан';
          break;

        case 2:
          statusText = 'Выдан';
          break;

        case 3:
          statusText = '';
          break;

        case 4:
          statusText = '';
          break;

        default:
          break;
      }

      return statusText;
    },
    formatDateTime(date) {
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    },
  },
  components: { DetailTemplate },
};
</script>

<style lang="scss">
.btn-polis-renew {
  margin-bottom: 10px;
}

.client-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  .info {
    h6 {
      font-weight: bold;
    }
  }
}
</style>
